var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("list-template", [
        _c(
          "div",
          { staticClass: "container", attrs: { slot: "side" }, slot: "side" },
          [
            _c("memo-list", {
              ref: "memoList",
              attrs: { novelId: _vm.novelId, memoId: _vm.memoId },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "container", attrs: { slot: "main" }, slot: "main" },
          [
            _c("memo-detail", {
              attrs: {
                novelId: _vm.novelId,
                memoId: _vm.memoId,
                isChanged: _vm.isChanged,
                onUpdatedIsChanged: _vm.onUpdatedIsChanged,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }